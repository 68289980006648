import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { AVAILABILITY } from "../../../../../constants/Menus/OrderMenuUtils";
import { useTranslation } from "react-i18next";
import { getGridColumnsByMenu } from "../../utils/GridUtils";
import { IPreFilterMonthAvailability } from "../../components/PreFilters/interfaces/IPreFilter";
import OrderBookPage from "../../components/HomePage/OrderBookPage";
import { OrderbookEnum } from "../../../../../utils/enums/OrderbookEnum";

const NEXT_MONTHS = 2;

const Availability: React.FC = () => {
	const { t } = useTranslation("order");
	const { setMenu, menu, reset } = useStores().listStore;
	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	const date = new Date();

	const monthAvailability: IPreFilterMonthAvailability = {
		startDate: new Date(date.getFullYear(), date.getMonth(), 1),
		endDate: new Date(date.setMonth(date.getMonth() + NEXT_MONTHS)),
	};

	React.useEffect(() => {
		reset();
		setMenu(AVAILABILITY);
		const gridColumns = getGridColumnsByMenu(t, AVAILABILITY);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{menu === AVAILABILITY && (
				<OrderBookPage
					orderbookTitleSource={"orderBookTrucks.orderBook"}
					orderbookSubTitleSource={"orderBookTrucks.availability"}
					orderbookPageTitleSource={"orderBookTrucks.pageTitle"}
					columns={columns}
					monthAvailability={monthAvailability}
					type={OrderbookEnum.Truck}
				/>
			)}
		</>
	);
};

export default observer(Availability);
