import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { toJS } from "mobx";

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
	const initialize = (connectionString) => {
		if (!connectionString) {
			throw new Error(
				"Instrumentation key not provided in ./src/telemetry-provider.jsx"
			);
		}

		reactPlugin = new ReactPlugin();

		appInsights = new ApplicationInsights({
			config: {
				connectionString: connectionString,
				maxBatchInterval: 0,
				disableFetchTracking: false,
				enableAutoRouteTracking: true,
				extensions: [reactPlugin],
			},
		});

		appInsights.addTelemetryInitializer((e) => {
			e.tags["ai.cloud.role"] =
				process.env.REACT_APP_CTADMVEN_APP_INSIGHTS_CLOUD_NAME ?? "";
		});

		appInsights.loadAppInsights();
	};

	return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const trackUserGroupEvent = (groups) => {
	if (!appInsights) return;
	groups = toJS(groups);
	groups.forEach((group) => {
		appInsights.trackEvent({
			name: "AuthGroup",
			properties: { group },
		});
	});
};
