import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./HomePageGrid";
import { getFollowUpGridColumns } from "../utils/GridUtils";
import { useStores } from "../stores/RootStore";
import { FOLLOWUP_MENU_ARG_TRUCK } from "../../../../constants/Menus/FollowUpMenuUtils";
import { ToastContainer } from "react-toastify";
import HomePageCsvExport from "./HomePageCsvExport";
import {
	groupsToBatchEditFollowUpArg,
	groupsToExportOrderCsvFollowUpArg,
} from "../../../../constants/AuthGroups/followUpArgPage";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import TrucksPreFilters from "./PreFilters/TrucksPreFilters";
import FollowUpArgBatchEditionModal from "./BatchEdition/FollowUpArgBatchEditionModal";
import SummaryTruck from "./Summary/SummaryTruck";

const TruckIndex: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { setMenu, menu, reset } = useStores().listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";
	const columns = getFollowUpGridColumns(t, language);
	const isAllowedToExportCsv = isAuthorizedGroup(
		groupsToExportOrderCsvFollowUpArg
	);
	const userCanDoBatchEdition = isAuthorizedGroup(groupsToBatchEditFollowUpArg);

	const fetch = React.useCallback(async () => {
		reset();
		setMenu(FOLLOWUP_MENU_ARG_TRUCK);
	}, [reset, setMenu]);

	React.useEffect(() => {
		fetch();
	}, [fetch]);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("followup.title", { ns: "menu" })} - ${t("truck", {
				ns: "menu",
			})}`,
			main: true,
		},
	];

	return (
		<Grid container spacing={2}>
			{menu === FOLLOWUP_MENU_ARG_TRUCK && (
				<>
					<Grid item xs={12}>
						<Box sx={{ display: "flex" }}>
							<TitleBox breadcrumbs={breadcrumbs} />
							{isAllowedToExportCsv && <HomePageCsvExport />}
							<TrucksPreFilters />
						</Box>
					</Grid>
					{userCanDoBatchEdition && <FollowUpArgBatchEditionModal />}
					<Grid item xs={12}>
						{columns && <HomePageGrid columns={columns} />}
					</Grid>
					<SummaryTruck />
					<ToastContainer />
				</>
			)}
		</Grid>
	);
};

export default observer(TruckIndex);
