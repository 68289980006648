import { action, configure, makeObservable, observable } from "mobx";
import {
	CompositeFilterDescriptor,
	FilterDescriptor,
	normalizeFilters,
} from "@progress/kendo-data-query";
import RootStore from "./RootStore";

configure({ enforceActions: "always" });

class FilterStore {
	@observable rootStore;
	@observable filters: CompositeFilterDescriptor | undefined = normalizeFilters(
		{
			logic: "and",
			filters: [],
		}
	);
	@observable hasPreFiltersApplied: boolean = false;
	@observable hasFormatError: boolean = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this);
	}

	@action setHasFormatError = (value: boolean) => (this.hasFormatError = value);

	@action cleanAllFilters = () => {
		this.cleanFilters();
		this.cleanPreFilters();
	};

	@action cleanFilters = () =>
		(this.filters = normalizeFilters({
			logic: "and",
			filters: [],
		}));

	@action cleanPreFilters = () => {
		if (!this.hasPreFiltersApplied) return;
		this.rootStore.preFilterChassiAOStore.clean();
		this.rootStore.preFilterLineSequenceDateStore.clean();
		this.rootStore.preFilterDeliveryProposalStore.clean();
		this.rootStore.preFilterMonthAvailabilityStore.clean();
		this.rootStore.preFilterLineStore.clean();
		this.rootStore.preFilterZoneStore.clean();
		this.rootStore.preFilterAsWeekStore.clean();
		this.rootStore.preFilterCommercialModelStore.clean();
		this.rootStore.preFilterSalesmanStore.clean();
		this.rootStore.preFilterQuotationCustomerStore.clean();
		this.rootStore.preFilterQuotationSalesmanStore.clean();
		this.rootStore.preFilterCustomerSASStore.clean();
		this.rootStore.preFilterCustomerOMStore.clean();
		this.hasPreFiltersApplied = false;
	};

	@action getFiltersAsCompositeFilterDescriptor = () =>
		this.filters
			? (this.filters.filters.slice() as CompositeFilterDescriptor[])
			: [];

	@action applyFilters = () => {
		this.cleanFilters();
		let andFilters = [] as Array<FilterDescriptor>;
		let orFiltersComposite = [] as Array<CompositeFilterDescriptor>;
		let orFiltersGroups = [] as Array<Array<FilterDescriptor>>;
		let allFiltersGroups = [] as Array<
			CompositeFilterDescriptor | FilterDescriptor
		>;

		andFilters =
			this.rootStore.preFilterChassiAOStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		andFilters =
			this.rootStore.preFilterDeliveryProposalStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterMonthAvailabilityStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterLineStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterZoneStore.getFilterDescriptor(orFiltersGroups);

		andFilters =
			this.rootStore.preFilterAsWeekStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCommercialModelStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterLineSequenceDateStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterCustomerSASStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterCustomerOMStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterSalesmanStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterQuotationCustomerStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterQuotationSalesmanStore.getFilterDescriptor(
			orFiltersGroups
		);

		orFiltersGroups.forEach((element) => {
			if (element.length > 0)
				orFiltersComposite.push({
					logic: "or",
					filters: element,
				} as CompositeFilterDescriptor);
		});

		if (andFilters.length > 0) allFiltersGroups.push(...andFilters);
		if (orFiltersComposite.length > 0)
			allFiltersGroups.push(...orFiltersComposite);
		if (allFiltersGroups.length > 0)
			this.filters?.filters.push(...allFiltersGroups);

		this.setHasPreFiltersApplied();
	};

	@action setHasPreFiltersApplied = () => {
		this.hasPreFiltersApplied = Boolean(
			this.filters && this.filters.filters.length > 0
		); //keep until filters object has only pre filters on it
	};
}

export default FilterStore;
