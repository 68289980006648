import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { ISelectOption } from "../interfaces/IPreFilter";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterZoneStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";
	@observable preFilterOptions: Array<ISelectOption> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		if (this.preFilter === "") return [];
		return [getFilterObject("zone", this.preFilter, "eq")];
	};

	@action set = (value: string) => {
		if (value !== "") this.rootStore.filterStore.hasPreFiltersApplied = true;

		this.preFilter = value;
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const zoneFilter = this.get();

		if (zoneFilter) orFiltersGroups.push(zoneFilter);
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterZoneStore;
