import { OrderMCDeliveryBatchEditProps } from "../../interfaces/BatchEdition/OrderMCDeliveryBatchEditProps";
import { dateFormModel } from "./DateFormModel";

export const orderMCDelivery: OrderMCDeliveryBatchEditProps = {
	deliveryProbability: "",
	billingForecast: dateFormModel,
	firmBilling: dateFormModel,
	financialDelivery: dateFormModel,
	physicalDelivery: dateFormModel,
	finalDelivery: dateFormModel,
};
