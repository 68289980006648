import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import ClearRecordItem from "../../../../../components/BatchEdition/ClearRecordItem";
import { followUpArgKeyLabels } from "../../utils/BatchEditionUtils";
import FinancialDeliveryForm from "./Form/FinancialDeliveryForm";

const OrderMCDeliveryContent: React.FC = () => {
	const { t } = useTranslation("order");

	const {
		batchData,
		deliveryProbabilityOptions,
		setMCDeliveryDataByKey,
		setDeliveryProbability,
	} = useStores().batchEditionStore;

	return (
		<>
			<Grid item sm={6} md={3} lg={3}>
				<SelectForm
					label={t(followUpArgKeyLabels.deliveryProbability.label)}
					labelId={`${followUpArgKeyLabels.deliveryProbability.label}-label`}
					value={batchData.orderMCDelivery.deliveryProbability.value}
					options={deliveryProbabilityOptions}
					size="small"
					editable
					onChange={(e: any) => setDeliveryProbability(e.target.value)}
				/>
			</Grid>
			<Grid item sm={6} md={3} lg={3}>
				<ClearRecordItem
					recordTitle={t(followUpArgKeyLabels.billingForecast.label)}
					checked={batchData.orderMCDelivery.billingForecast.clearValue}
					onClearRecord={setMCDeliveryDataByKey}
					recordKey={followUpArgKeyLabels.billingForecast.key}
				>
					<DateForm
						label={t(followUpArgKeyLabels.billingForecast.label)}
						inputFormat="MMMM/yy"
						value={batchData.orderMCDelivery.billingForecast.value}
						editable
						onChange={(event: any) =>
							setMCDeliveryDataByKey(
								{
									value: event,
									clearValue: false,
								},
								followUpArgKeyLabels.billingForecast.key
							)
						}
						disabled={batchData.orderMCDelivery.billingForecast.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={6} md={3} lg={3}>
				<ClearRecordItem
					recordTitle={t(followUpArgKeyLabels.firmBilling.label)}
					checked={batchData.orderMCDelivery.firmBilling.clearValue}
					onClearRecord={setMCDeliveryDataByKey}
					recordKey={followUpArgKeyLabels.firmBilling.key}
				>
					<DateForm
						label={t(followUpArgKeyLabels.firmBilling.label)}
						inputFormat="dd/MM/yy"
						value={batchData.orderMCDelivery.firmBilling.value}
						editable
						onChange={(event: any) =>
							setMCDeliveryDataByKey(
								{
									value: event,
									clearValue: false,
								},
								followUpArgKeyLabels.firmBilling.key
							)
						}
						disabled={batchData.orderMCDelivery.firmBilling.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
			<Grid item sm={6} md={3} lg={3}>
				<FinancialDeliveryForm />
			</Grid>
			<Grid item sm={6} md={3} lg={3}>
				<ClearRecordItem
					recordTitle={t(followUpArgKeyLabels.physicalDelivery.label)}
					checked={batchData.orderMCDelivery.physicalDelivery.clearValue}
					onClearRecord={setMCDeliveryDataByKey}
					recordKey={followUpArgKeyLabels.physicalDelivery.key}
				>
					<DateForm
						label={t(followUpArgKeyLabels.physicalDelivery.label)}
						inputFormat="dd/MM/yy"
						value={batchData.orderMCDelivery.physicalDelivery.value}
						editable
						onChange={(event: any) =>
							setMCDeliveryDataByKey(
								{
									value: event,
									clearValue: false,
								},
								followUpArgKeyLabels.physicalDelivery.key
							)
						}
						disabled={batchData.orderMCDelivery.physicalDelivery.clearValue}
					/>
				</ClearRecordItem>
			</Grid>
		</>
	);
};

export default observer(OrderMCDeliveryContent);
