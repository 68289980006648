import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { Grid } from "@mui/material";
import { useStores } from "../../stores/RootStore";

const QuotationID: React.FC = () => {
	const { t } = useTranslation("quotation");
	const { set, preFilter } = useStores().preFilterQuotationIDStore;
	const { applyFilters } = useStores().filterStore;

	return (
		<Grid item xs={6}>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				label={t("preFilters.quotationId")}
				tooltip={t("tooltips.multipleSearch")}
				editable
			/>
		</Grid>
	);
};

export default observer(QuotationID);
