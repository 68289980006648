import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import SelectForm from "../../../../../../components/FormInputs/SelectForm";
import { getDefaultStringOption } from "../../../../../../utils/SelectOptions";
import { Grid } from "@mui/material";

const PhysicalDeliveryPreFilter: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);
	const { preFilter, preFilterOptions, set } =
		useStores().preFilterPhysicalDeliveryStore;
	const defaultItem = getDefaultStringOption(t);

	return (
		<Grid item xs={3}>
			<SelectForm
				label={t("preFilters.physicalDelivery")}
				labelId={"pre-filter-physicalDelivery-label"}
				value={preFilter}
				options={preFilterOptions}
				size="small"
				editable
				defaultItem={defaultItem}
				onChange={(e) => set(e.target.value)}
			/>
		</Grid>
	);
};

export default observer(PhysicalDeliveryPreFilter);
