import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import Line from "./containers/Line";
import PreFilters from "./PreFilters";
import { ITrucksPreFilters } from "./interfaces/ITrucksPreFilters";
import AsWeek from "./containers/AsWeek";
import ChassiAO from "./containers/ChassiAO";
import DeliveryProposal from "./containers/DeliveryProposal";
import LineSequenceDate from "./containers/LineSequenceDate";
import CommercialModel from "./containers/CommercialModel";
import MonthAvailability from "./containers/MonthAvailability";
import Zone from "./containers/Zone";
import Salesman from "./containers/Salesman";
import QuotationCustomer from "./containers/QuotationCustomer";
import QuotationSalesman from "./containers/QuotationSalesman";
import CustomerOM from "./containers/CustomerOM";
import CustomerSAS from "./containers/CustomerSAS";

const TrucksPreFilters: React.FC<ITrucksPreFilters> = ({
	monthAvailability,
}) => {
	const { getOptions: getOptionsLine } = useStores().preFilterLineStore;
	const { menu } = useStores().listStore;

	React.useEffect(() => {
		getOptionsLine();
	}, [getOptionsLine, menu]);

	return (
		<PreFilters>
			<ChassiAO type="chassiAO" />
			<DeliveryProposal />
			<MonthAvailability {...monthAvailability} />
			<LineSequenceDate />
			<Line />
			<Zone />
			<AsWeek />
			<CommercialModel />
			<CustomerSAS />
			<CustomerOM />
			<Salesman />
			<QuotationCustomer />
			<QuotationSalesman />
		</PreFilters>
	);
};

export default observer(TrucksPreFilters);
