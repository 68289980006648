export const followUpArgKeyLabels = {
	deliveryProbability: {
		label: "details.sections.mktCompanyDelivery.deliveryProbability",
	},
	billingForecast: {
		key: "billingForecast",
		label: "details.sections.forecastBilling.billingForecast",
	},
	firmBilling: {
		key: "firmBilling",
		label: "details.sections.mktCompanyDelivery.firmBillingDate",
	},
	financialDelivery: {
		key: "financialDelivery",
		label: "details.sections.mktCompanyDelivery.financialDelivery",
	},
	physicalDelivery: {
		key: "physicalDelivery",
		label: "details.sections.mktCompanyDelivery.physicalDelivery",
	},
	dealer: {
		key: "dealerId",
		label: "details.sections.customer.dealer",
	},
	sasCustomer: {
		label: "details.sections.customer.sasCustomer",
	},
	destination: {
		label: "details.sections.forecastBilling.destination",
	},
	currency: {
		key: "currency",
		label: "details.sections.mktCompanyCommercial.currency",
	},
	paymentForm: {
		key: "paymentTermsId",
		label: "details.sections.mktCompanyCommercial.paymentForm",
	},
	shippingDate: {
		key: "shippingDate",
		label: "details.sections.forecastBilling.shippingDate",
	},
	certificateDate: {
		key: "certificateDate",
		label: "details.sections.forecastBilling.certificateDate",
	},
	paquete: {
		key: "paqueteId",
		label: "details.sections.mktCompanyCommercial.paquete",
	},
	vehicleLocation: {
		key: "vehicleLocationId",
		label: "details.sections.mktCompanyCommercial.vehicleLocation",
	},
};
