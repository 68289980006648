import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import PaqueteForm from "./Form/PaqueteForm";

const EngineeringUserContent: React.FC = () => {
	return (
		<Grid item sm={6} md={3} lg={3}>
			<PaqueteForm />
		</Grid>
	);
};

export default observer(EngineeringUserContent);
