import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "../../../stores/RootStore";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import { IPreFilterMonthAvailability } from "../interfaces/IPreFilter";
import { Grid } from "@mui/material";

const MonthAvailabilityPreFilter: React.FC<IPreFilterMonthAvailability> = ({
	startDate = moment().startOf("month").month(0).year(2020).toDate(),
	endDate = moment().startOf("month").month(0).year(2030).toDate(),
}) => {
	const { t } = useTranslation("order");
	const { set, preFilter } = useStores().preFilterMonthAvailabilityStore;
	const [date, setDate] = useState<Date | null>(
		preFilter ? preFilter.startDate : null
	);

	const handleChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (
			moment(dataItem, "MM/yyyy", true).isValid() &&
			newDateMoment.toDate() >= startDate &&
			newDateMoment.toDate() <= endDate
		) {
			set({
				startDate: newDateMoment.startOf("month").toDate(),
				endDate: newDateMoment.endOf("month").toDate(),
			});
		} else if (dataItem == null) {
			set(undefined);
		}
		setDate(dataItem);
	};

	return (
		<Grid item xs={6}>
			<DateForm
				editable
				label={t("preFilters.monthAvailability")}
				inputFormat="MM/yyyy"
				value={date}
				onChange={handleChange}
				size="small"
				minDate={startDate}
				maxDate={endDate}
			/>
		</Grid>
	);
};

export default observer(MonthAvailabilityPreFilter);
