import { OrderMCManagementDetailBatchEditProps } from "../../interfaces/BatchEdition/OrderMCManagementDetailBatchEditProps";
import { dateFormModel } from "./DateFormModel";
import { stringFormModel } from "./StringFormModel";

export const orderMCManagementDetail: OrderMCManagementDetailBatchEditProps = {
	destination: stringFormModel,
	currency: stringFormModel,
	paymentTermsId: stringFormModel,
	shippingDate: dateFormModel,
	certificateDate: dateFormModel,
	dealerId: stringFormModel,
	vehicleLocationId: stringFormModel,
	paqueteId: stringFormModel,
};
