import { action, configure, makeObservable, observable } from "mobx";
import {
	CompositeFilterDescriptor,
	FilterDescriptor,
	normalizeFilters,
} from "@progress/kendo-data-query";
import RootStore from "./RootStore";

configure({ enforceActions: "always" });

class FilterStore {
	@observable rootStore;
	@observable filters: CompositeFilterDescriptor | undefined = normalizeFilters(
		{
			logic: "and",
			filters: [],
		}
	);
	@observable hasPreFiltersApplied: boolean = false;
	@observable modalOpen: boolean = false;
	@observable hasFormatError: boolean = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this);
	}

	@action setHasFormatError = (value: boolean) => (this.hasFormatError = value);

	@action setModalOpen = () => (this.modalOpen = true);
	@action setModalClosed = () => (this.modalOpen = false);

	@action cleanAllFilters = () => {
		this.cleanFilters();
		this.cleanPreFilters();
	};

	@action cleanFilters = () =>
		(this.filters = normalizeFilters({
			logic: "and",
			filters: [],
		}));

	@action cleanPreFilters = () => {
		this.rootStore.preFilterDealerStore.clean();
		this.rootStore.preFilterDestinationStore.clean();
		this.rootStore.preFilterPaymentFormStore.clean();
		this.rootStore.preFilterAsWeekDateStore.clean();
		this.rootStore.preFilterGokDateStore.clean();
		this.rootStore.preFilterShippingDateStore.clean();
		this.rootStore.preFilterCertificateDateStore.clean();
		this.rootStore.preFilterVehicleLocationStore.clean();
		this.rootStore.preFilterChassiAOStore.clean();
		this.rootStore.preFilterCustomerStore.clean();
		this.rootStore.preFilterBusinessControlVehicleStore.clean();
		this.rootStore.preFilterPrevisionBillingMonthStore.clean();
		this.rootStore.preFilterCurrencyStore.clean();

		this.hasPreFiltersApplied = false;
	};

	@action getFiltersAsCompositeFilterDescriptor = () =>
		this.filters
			? (this.filters.filters.slice() as CompositeFilterDescriptor[])
			: [];

	@action applyFilters = () => {
		this.cleanFilters();
		let andFilters = [] as Array<FilterDescriptor>;
		let orFiltersComposite = [] as Array<CompositeFilterDescriptor>;
		let orFiltersGroups = [] as Array<Array<FilterDescriptor>>;
		let allFiltersGroups = [] as Array<
			CompositeFilterDescriptor | FilterDescriptor
		>;

		//one option
		andFilters =
			this.rootStore.preFilterDestinationStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterGokDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterAsWeekDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterShippingDateStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterCertificateDateStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterCustomerStore.getFilterDescriptor(andFilters);
		andFilters =
			this.rootStore.preFilterBusinessControlVehicleStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterPrevisionBillingMonthStore.getFilterDescriptor(
				andFilters
			);
		andFilters =
			this.rootStore.preFilterCurrencyStore.getFilterDescriptor(andFilters);

		//multi options
		this.rootStore.preFilterDealerStore.getFilterDescriptor(orFiltersGroups);
		this.rootStore.preFilterPaymentFormStore.getFilterDescriptor(
			orFiltersGroups
		);
		this.rootStore.preFilterVehicleLocationStore.getFilterDescriptor(
			orFiltersGroups
		);

		//both options
		andFilters =
			this.rootStore.preFilterChassiAOStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		orFiltersGroups.forEach((element) => {
			if (element.length > 0)
				orFiltersComposite.push({
					logic: "or",
					filters: element,
				} as CompositeFilterDescriptor);
		});

		if (andFilters.length > 0) allFiltersGroups.push(...andFilters);
		if (orFiltersComposite.length > 0)
			allFiltersGroups.push(...orFiltersComposite);
		if (allFiltersGroups.length > 0)
			this.filters?.filters.push(...allFiltersGroups);

		this.setHasPreFiltersApplied();
	};

	@action setHasPreFiltersApplied = () => {
		this.hasPreFiltersApplied = Boolean(
			this.filters && this.filters.filters.length > 0
		); //keep until filters object has only pre filters on it
	};
}

export default FilterStore;
