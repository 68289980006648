import { orderMCDelivery } from "./BatchEdition/OrderMCDeliveryBatchEdit";
import {
	BatchDataProps,
	BatchEngineeringUserDataProps,
} from "../interfaces/BatchEdition/BatchDataProps";
import { orderMCManagementDetail } from "./BatchEdition/OrderMCManagementDetailBatchEdit";
import { stringFormModel } from "./BatchEdition/StringFormModel";

export const batchDataModel: BatchDataProps = {
	orderMCDelivery: orderMCDelivery,
	orderMCManagementDetail: orderMCManagementDetail,
	customerSAS: stringFormModel,
	itemsToBeUpdated: [],
};

export const batchEngineeringUserDataModel: BatchEngineeringUserDataProps = {
	paqueteId: stringFormModel,
	itemsToBeUpdated: [],
};
