import { action, configure, makeObservable, observable } from "mobx";
import {
	CompositeFilterDescriptor,
	FilterDescriptor,
	normalizeFilters,
} from "@progress/kendo-data-query";
import RootStore from "./RootStore";

configure({ enforceActions: "always" });

class FilterStore {
	@observable rootStore;
	@observable filters: CompositeFilterDescriptor | undefined = normalizeFilters(
		{
			logic: "and",
			filters: [],
		}
	);
	@observable hasPreFiltersApplied: boolean = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeObservable(this);
	}

	@action cleanAllFilters = () => {
		this.cleanFilters();
		this.cleanPreFilters();
	};

	@action cleanFilters = () =>
		(this.filters = normalizeFilters({
			logic: "and",
			filters: [],
		}));

	@action cleanPreFilters = () => {
		if (!this.hasPreFiltersApplied) return;

		this.rootStore.preFilterQuotationIDStore.clean();
		this.rootStore.preFilterQuotationDateStore.clean();
		this.rootStore.preFilterStatusSASStore.clean();
		this.rootStore.preFilterFactoryOrderStore.clean();
		this.rootStore.preFilterChassiStore.clean();
		this.rootStore.preFilterSalesmanStore.clean();
		this.rootStore.preFilterReservationStatusStore.clean();
		this.hasPreFiltersApplied = false;
	};

	@action getFiltersAsCompositeFilterDescriptor = () =>
		this.filters
			? (this.filters.filters.slice() as CompositeFilterDescriptor[])
			: [];

	@action applyFilters = () => {
		this.cleanFilters();
		let andFilters = [] as Array<FilterDescriptor>;
		let orFiltersComposite = [] as Array<CompositeFilterDescriptor>;
		let orFiltersGroups = [] as Array<Array<FilterDescriptor>>;
		let allFiltersGroups = [] as Array<
			CompositeFilterDescriptor | FilterDescriptor
		>;

		andFilters =
			this.rootStore.preFilterQuotationIDStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		andFilters =
			this.rootStore.preFilterQuotationDateStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterStatusSASStore.getFilterDescriptor(orFiltersGroups);

		this.rootStore.preFilterReservationStatusStore.getFilterDescriptor(
			orFiltersGroups
		);

		andFilters =
			this.rootStore.preFilterFactoryOrderStore.getFilterDescriptor(andFilters);

		andFilters =
			this.rootStore.preFilterChassiStore.getFilterDescriptor(andFilters);

		this.rootStore.preFilterSalesmanStore.getFilterDescriptor(orFiltersGroups);

		orFiltersGroups.forEach((element) => {
			if (element.length > 0)
				orFiltersComposite.push({
					logic: "or",
					filters: element,
				} as CompositeFilterDescriptor);
		});

		if (andFilters.length > 0) allFiltersGroups.push(...andFilters);
		if (orFiltersComposite.length > 0)
			allFiltersGroups.push(...orFiltersComposite);
		if (allFiltersGroups.length > 0)
			this.filters?.filters.push(...allFiltersGroups);

		this.setHasPreFiltersApplied();
	};

	@action setHasPreFiltersApplied = () => {
		this.hasPreFiltersApplied = Boolean(
			this.filters && this.filters.filters.length > 0
		); //keep until filters object has only pre filters on it
	};
}

export default FilterStore;
