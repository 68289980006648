import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { Grid, GridProps } from "@mui/material";

const CustomerSASPreFilter: React.FC<GridProps> = ({ xs }) => {
	const { t } = useTranslation("order");
	const { set, preFilter } = useStores().preFilterCustomerStoreSAS;
	const { applyFilters } = useStores().filterStore;

	return (
		<Grid item xs={xs}>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				id="customerSASPreFilter"
				label={t(`preFilters.customerSAS`)}
				size="small"
				inputProps={{ maxLength: 100 }}
				editable
			/>
		</Grid>
	);
};

export default observer(CustomerSASPreFilter);
