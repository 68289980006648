import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

const fieldName: string = "reservations.chassi";

class PreFilterChassiStore {
	@observable rootStore;
	@observable preFilter: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		if (this.preFilter === "") return [];

		const filters = this.preFilter.replace(" ", ",");

		return [getFilterObject(fieldName, filters, "eq")];
	};

	@action set = (value: string) => (this.preFilter = value);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const chassiFilters = this.get();
		if (chassiFilters) andFilters = andFilters.concat(chassiFilters);

		return andFilters;
	}
}

export default PreFilterChassiStore;
