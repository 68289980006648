import React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./QuotationGrid";
import { getQuotationGridColumns } from "../utils/GridUtils";
import PreFilters from "../PreFilters/PreFilters";
import QuotationID from "../PreFilters/containers/QuotationID";
import { useStores } from "../stores/RootStore";
import QuotationDate from "../PreFilters/containers/QuotationDate";
import StatusSAS from "../PreFilters/containers/StatusSAS";
import FactoryOrder from "../PreFilters/containers/FactoryOrder";
import Chassi from "../PreFilters/containers/Chassi";
import Salesman from "../PreFilters/containers/Salesman";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import ReservationStatus from "../PreFilters/containers/ReservationStatus";
import {
	groupsAllowedToSeeQuotationMenu,
	groupsQuotationManager,
	groupsToExportQuotationCsv,
} from "../../../../constants/AuthGroups/quotationPage";
import QuotationEditionModal from "./QuotationEditionModal";
import { ToastContainer } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import {
	getQuotationReservationStatusOptions,
	getQuotationStatusSASOptions,
	getQuotationWorkflowStatusOptions,
} from "../../../../utils/SelectOptions";
import HomePageCsvExport from "./HomePageCsvExport";

const QuotationIndex: React.FC = () => {
	const { t } = useTranslation(["quotation", "menu"]);
	const { reset } = useStores().listStore;
	const { getSalesmanOptions } = useStores().preFilterSalesmanStore;
	const { setIsQuotationManagerUser, loading } = useStores().listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const isAllowedToExportCsv = isAuthorizedGroup(groupsToExportQuotationCsv);

	const isQuotationManagerUser = setIsQuotationManagerUser(
		isAuthorizedGroup(groupsQuotationManager)
	);

	const canUserSeeBatchEdition = isAuthorizedGroup(
		groupsAllowedToSeeQuotationMenu
	);

	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	var statusSASOptions = getQuotationStatusSASOptions(t);
	var reservationStatusOptions = getQuotationReservationStatusOptions(t);
	var workflowStatusOptions = getQuotationWorkflowStatusOptions(t);

	React.useEffect(() => {
		reset();
		if (isQuotationManagerUser) {
			getSalesmanOptions();
		}
		const gridColumns = getQuotationGridColumns(
			t,
			statusSASOptions,
			reservationStatusOptions,
			workflowStatusOptions
		);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quotation.title", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			<Grid item lg={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					{isAllowedToExportCsv && <HomePageCsvExport />}
					{canUserSeeBatchEdition && <QuotationEditionModal />}
					<PreFilters>
						<QuotationID />
						{isQuotationManagerUser && <Salesman />}
						<QuotationDate />
						<FactoryOrder />
						<Chassi />
						<StatusSAS />
						<ReservationStatus />
					</PreFilters>
				</Box>
			</Grid>
			<Grid item lg={12}>
				<LoadingPage loading={loading} />
				{columns && <HomePageGrid columns={columns} />}
			</Grid>
			<ToastContainer />
		</>
	);
};

export default observer(QuotationIndex);
