import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import {
	FollowUpArgAccountingEditableProps,
	FollowUpArgArgMarketCompanyEditableProps,
	FollowUpArgBusinessEditableProps,
	FollowUpArgEngineeringEditableProps,
	FollowUpArgFinancialEditableProps,
	FollowUpArgGeneralCommentEditableProps,
	FollowUpArgLogisticEditableProps,
} from "../../pages/FollowUpArg/Details/interfaces/Editable/FollowUpArgEditable";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { VehicleType } from "../../utils/GenericTypes";

interface IFollowUpArgService {
	listPaginated(dataState: State, menu: string): Promise<any>;
	listBusPaginated(dataState: State, menu: string): Promise<any>;
	getDetails(id: string): Promise<any>;
	getDetailsBus(id: string): Promise<any>;
	getDetailsVariants(id: string): Promise<any>;
	getDetailsVariantsBus(id: string): Promise<any>;
	getDetailsMCDelivery(id: string): Promise<any>;
	getDetailsMCManagement(id: string): Promise<any>;
	getShippingCompanies(): Promise<any>;
	update(orderDetails: FollowUpArgArgMarketCompanyEditableProps): Promise<any>;
	updateGeneralComment(
		orderDetails: FollowUpArgGeneralCommentEditableProps
	): Promise<any>;
	updateAsFinancial(
		orderDetails: FollowUpArgFinancialEditableProps
	): Promise<any>;
	updateAsLogistic(
		orderDetails: FollowUpArgLogisticEditableProps
	): Promise<any>;
	updateAsAccounting(
		orderDetails: FollowUpArgAccountingEditableProps
	): Promise<any>;
	updateAsBusiness(
		orderDetails: FollowUpArgBusinessEditableProps
	): Promise<any>;
	updateAsEngineering(
		orderDetails: FollowUpArgEngineeringEditableProps
	): Promise<any>;
	csvExport(dataState: State, csvOptions: CsvOptionsProps): Promise<any>;
	csvExportBus(dataState: State, csvOptions: CsvOptionsProps): Promise<any>;
	updateBatch(orderDetails: any): Promise<any>;
	updateBatchBus(orderDetails: any): Promise<any>;
	updateBatchFinancialUser(orderDetails: any): Promise<any>;
	updateBatchFinancialUserBus(orderDetails: any): Promise<any>;
	updateBatchEngineeringUser(orderDetails: any): Promise<any>;
}

const base = "/followUpArg";

export class FollowUpArgService implements IFollowUpArgService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?${toDataSourceRequestString(dataState)}`
		);

	listBusPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/listBus?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/details/${id}`);

	getDetailsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/detailsBus/${id}`);

	getDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${id}`);

	getDetailsVariantsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variantsBus/${id}`);

	getDetailsMCDelivery = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcDelivery/${id}`);

	getDetailsMCManagement = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcManagement/${id}`);

	getShippingCompanies = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/shippingCompanies`);

	update = async (
		orderDetails: FollowUpArgArgMarketCompanyEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/update`, JSON.stringify(orderDetails));

	updateGeneralComment = async (
		orderDetails: FollowUpArgGeneralCommentEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateGeneralComment`,
			JSON.stringify(orderDetails)
		);

	updateAsFinancial = async (
		orderDetails: FollowUpArgFinancialEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsFinancial`,
			JSON.stringify(orderDetails)
		);

	updateAsLogistic = async (
		orderDetails: FollowUpArgLogisticEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsLogistic`,
			JSON.stringify(orderDetails)
		);

	updateAsAccounting = async (
		orderDetails: FollowUpArgAccountingEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsAccounting`,
			JSON.stringify(orderDetails)
		);

	updateAsBusiness = async (
		orderDetails: FollowUpArgBusinessEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsBusiness`,
			JSON.stringify(orderDetails)
		);

	updateAsEngineering = async (
		orderDetails: FollowUpArgEngineeringEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsEngineering`,
			JSON.stringify(orderDetails)
		);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	csvExportBus = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csvBus?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	updateBatch = async (orderDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/batch`, JSON.stringify(orderDetails));

	updateBatchBus = async (orderDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchBus`,
			JSON.stringify(orderDetails)
		);

	updateBatchFinancialUser = async (orderDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchFinancialUser`,
			JSON.stringify(orderDetails)
		);

	updateBatchFinancialUserBus = async (orderDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchFinancialUserBus`,
			JSON.stringify(orderDetails)
		);

	updateBatchEngineeringUser = async (orderDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchEngineeringUser`,
			JSON.stringify(orderDetails)
		);

	getSummaryTruck = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/summaryTruck`);

	getSummaryBus = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/summaryBus`);

	getProgressSummary = async (vehicleType: VehicleType): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/progressSummary?vehicleType=${vehicleType}`
		);
}
