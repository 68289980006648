export const followUpChlKeyLabels = {
	zone: {
		key: "zone",
		label: "preFilters.zone",
	},
	customer: {
		key: "customer",
		label: "details.sections.customer.title",
	},
	generalComments: {
		key: "generalComments",
		label: "details.sections.generalComments.title",
	},
};
