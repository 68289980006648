import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { Grid } from "@mui/material";
import { VehicleType } from "../../../../../../utils/GenericTypes";

type ChassiAOProps = {
	type: "chassi" | "chassiAO" | "chassiFO" | "chassiFOOM";
	vehicleType: VehicleType;
};
const ChassiAO: React.FC<ChassiAOProps> = ({ type, vehicleType }) => {
	const { t } = useTranslation("order");
	const { set, preFilter, hasFormatError } = useStores().preFilterChassiAOStore;
	const { applyFilters } = useStores().filterStore;

	return (
		<Grid item xs={12}>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				id={type}
				label={t(`preFilters.${type}`)}
				tooltip={
					vehicleType === "Bus"
						? t("tooltips.chassiBusSearch")
						: t("tooltips.multipleSearch")
				}
				size="small"
				editable
				error={hasFormatError}
			/>
		</Grid>
	);
};

export default observer(ChassiAO);
