import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { Grid } from "@mui/material";
import AutoCompleteForm from "../../../../../components/FormInputs/AutoCompleteForm";

const SalesmanPreFilter: React.FC = () => {
	const { t } = useTranslation(["quotation"]);
	const { preFilter, preFilterOptions, set } =
		useStores().preFilterSalesmanStore;

	return (
		<Grid item xs={6}>
			<AutoCompleteForm
				label={t("preFilters.salesman")}
				labelId={"preFilters.salesman"}
				value={preFilter}
				options={preFilterOptions}
				editable
				hasEmptyDefaultItem
				onChange={(value) => {
					set(value);
				}}
				isCustomSearch
				editTooltip={t("tooltips.salesmanSearch")}
			/>
		</Grid>
	);
};

export default observer(SalesmanPreFilter);
