import React from "react";
import { observer } from "mobx-react";
import PreFilters from "./PreFilters";
import BusApplication from "./containers/BusApplication";
import ChassiAO from "./containers/ChassiAO";
import Region from "./containers/Region";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
} from "../../../../../constants/Menus/OrderMenuUtils";
import { groupsAllowedToSeeRegionPreFilter } from "../../../../../constants/AuthGroups/orderListBus";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import AsWeek from "./containers/AsWeek";
import ProductionModel from "./containers/CommercialModel";
import LineSequenceDate from "./containers/LineSequenceDate";
import CustomerSAS from "./containers/CustomerSAS";
import CustomerOM from "./containers/CustomerOM";

const BusPreFilters: React.FC = () => {
	const { getOptions: getOptionsRegion, setIsTracbelUser } =
		useStores().preFilterRegionStore;
	const { isAuthorizedGroup } = useAuthStore();
	const { menu } = useStores().listStore;

	setIsTracbelUser(isAuthorizedGroup([BRA_DEALER_TNE_TNO]));

	const shouldShowRegionPreFilter =
		isAuthorizedGroup(groupsAllowedToSeeRegionPreFilter) ||
		menu === BUS_FREE_UNIT_VCI ||
		menu === BUS_FREE_UNIT_VCI_VDB;

	React.useEffect(() => {
		if (shouldShowRegionPreFilter) {
			getOptionsRegion(menu);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

	return (
		<PreFilters>
			<ChassiAO type="chassiFO" />
			<LineSequenceDate />
			{shouldShowRegionPreFilter && <Region />}
			<BusApplication />
			<AsWeek />
			<ProductionModel />
			<CustomerSAS />
			<CustomerOM />
		</PreFilters>
	);
};

export default observer(BusPreFilters);
