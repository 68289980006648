import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "../../../stores/RootStore";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import { Grid } from "@mui/material";

const DeliveryProposalPreFilter: React.FC = () => {
	const { t } = useTranslation("order");
	const { set, preFilter } = useStores().preFilterDeliveryProposalStore;
	const startOfMonthDate = moment().startOf("month");
	const [date, setDate] = useState<Date | null>(
		preFilter ? preFilter.startDate : null
	);

	const handleChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (
			moment(dataItem, "MM/yyyy", true).isValid() &&
			newDateMoment.year() >= 2020 &&
			newDateMoment.year() <= 2030
		) {
			set({
				startDate: newDateMoment.startOf("month").toDate(),
				endDate: newDateMoment.endOf("month").toDate(),
			});
		} else if (dataItem == null) {
			set(undefined);
		}
		setDate(dataItem);
	};

	return (
		<Grid item xs={6}>
			<DateForm
				editable
				label={t("preFilters.deliveryProposal")}
				inputFormat="MM/yyyy"
				value={date}
				onChange={handleChange}
				size="small"
				minDate={startOfMonthDate.month(0).year(2020).toDate()}
				maxDate={startOfMonthDate.month(0).year(2030).toDate()}
			/>
		</Grid>
	);
};

export default observer(DeliveryProposalPreFilter);
