import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import ChassiAO from "./containers/ChassiAO";
import DeliveryProposal from "./containers/DeliveryProposal";
import MonthAvailability from "./containers/MonthAvailability";
import Line from "./containers/Line";
import Region from "./containers/Region";
import PreFilters from "./PreFilters";
import { ITrucksPreFilters } from "./interfaces/ITrucksPreFilters";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { AVAILABILITY } from "../../../../../constants/Menus/OrderMenuUtils";
import { groupsAllowedToSeeRegionPreFilter } from "../../../../../constants/AuthGroups/orderListTruck";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import AsWeek from "./containers/AsWeek";
import CommercialModel from "./containers/CommercialModel";
import LineSequenceDate from "./containers/LineSequenceDate";
import CustomerSAS from "./containers/CustomerSAS";
import CustomerOM from "./containers/CustomerOM";

const TrucksPreFilters: React.FC<ITrucksPreFilters> = ({
	monthAvailability,
}) => {
	const { getOptions: getOptionsLine } = useStores().preFilterLineStore;
	const { getOptions: getOptionsRegion, setIsTracbelUser } =
		useStores().preFilterRegionStore;
	const { isAuthorizedGroup } = useAuthStore();

	const { menu } = useStores().listStore;

	setIsTracbelUser(
		isAuthorizedGroup([BRA_DEALER_TNE_TNO]) && menu !== AVAILABILITY
	);

	const shouldShowRegionPreFilter =
		isAuthorizedGroup(groupsAllowedToSeeRegionPreFilter) ||
		menu === AVAILABILITY;

	React.useEffect(() => {
		getOptionsLine();

		if (shouldShowRegionPreFilter) {
			getOptionsRegion(menu);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

	return (
		<PreFilters>
			<ChassiAO type="chassiAO" />
			<DeliveryProposal />
			<MonthAvailability {...monthAvailability} />
			<LineSequenceDate />
			<Line />
			{shouldShowRegionPreFilter && <Region />}
			<AsWeek />
			<CommercialModel />
			<CustomerSAS />
			<CustomerOM />
		</PreFilters>
	);
};

export default observer(TrucksPreFilters);
