import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import PreFilters from "./PreFilters";
import { useTranslation } from "react-i18next";
import DealerPreFilter from "./containers/DealerPreFilter";
import DestinationPreFilter from "./containers/DestinationPreFilter";
import PaymentFormPreFilter from "./containers/PaymentFormPreFilter";
import ShippingDatePreFilter from "./containers/ShippingDatePreFilter";
import VehicleLocationPreFilter from "./containers/VehicleLocationPreFilter";
import CertificateDatePreFilter from "./containers/CertificateDatePreFilter";
import ChassiAOPreFilter from "./containers/ChassiAOPreFilter";
import CustomerPreFilter from "./containers/CustomerPreFilter";
import GokDatePreFilter from "./containers/GokDatePreFilter";
import AsWeekDatePreFilter from "./containers/AsWeekDatePreFilter";
import BusinessControlVehiclePreFilter from "./containers/BusinessControlVehiclePreFilter";
import PrevisionBillingMonthPreFilter from "./containers/PrevisionBillingMonthPreFilter";
import CurrencyPreFilter from "./containers/CurrencyPreFilter";
import { getCurrencyEnumOptions } from "../../../../../utils/SelectOptions";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { ARG_DEALERS } from "../../../../../constants/AuthGroups/authGroups";

const TrucksPreFilters: React.FC = () => {
	const { t, i18n } = useTranslation(["order", "general"]);
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const { cleanAllFilters } = useStores().filterStore;
	const { getOptions: getOptionsDealer } = useStores().preFilterDealerStore;
	const { getOptions: getOptionsPaymentForm } =
		useStores().preFilterPaymentFormStore;
	const { getOptions: getOptionsVehicleLocation } =
		useStores().preFilterVehicleLocationStore;
	const { setOptions: setOptionsCurrency } = useStores().preFilterCurrencyStore;

	const currencyEnumOptions = getCurrencyEnumOptions(t);

	const { isAuthorizedGroup } = useAuthStore();
	const shouldShowDealerPreFilter = !isAuthorizedGroup(ARG_DEALERS);

	const fetchOptions = React.useCallback(() => {
		cleanAllFilters();
		getOptionsPaymentForm(language);
		getOptionsVehicleLocation();
		setOptionsCurrency(currencyEnumOptions);
		if (shouldShowDealerPreFilter) {
			getOptionsDealer();
		}
	}, [
		cleanAllFilters,
		getOptionsPaymentForm,
		language,
		getOptionsVehicleLocation,
		getOptionsDealer,
		setOptionsCurrency,
		currencyEnumOptions,
		shouldShowDealerPreFilter,
	]);

	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	return (
		<PreFilters>
			<ChassiAOPreFilter vehicleType="Truck" type="chassiAO" />
			<PrevisionBillingMonthPreFilter />
			{shouldShowDealerPreFilter && <DealerPreFilter />}
			<CustomerPreFilter xs={shouldShowDealerPreFilter ? 12 : 6} />
			<DestinationPreFilter />
			<CurrencyPreFilter />
			<PaymentFormPreFilter />
			<VehicleLocationPreFilter />
			<GokDatePreFilter />
			<AsWeekDatePreFilter />
			<ShippingDatePreFilter />
			<CertificateDatePreFilter />
			<BusinessControlVehiclePreFilter />
		</PreFilters>
	);
};

export default observer(TrucksPreFilters);
