import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { ISelectOption } from "../interfaces/IPreFilter";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCountryStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";
	@observable preFilterOptions: Array<ISelectOption> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: this.preFilter
					.split(",")
					.map((d: string) => getFilterObject("countryName", d, "startswith"));

	@action set = (region: string) => (this.preFilter = region);

	@action getOptions = async (menu: string) => {
		if (this.preFilterOptions.length > 0) return;

		this.loading = true;
		await this.api.europeFactoryService
			.getCountryFilterOptions(menu)
			.then((rs: any) => {
				if (rs.data)
					this.preFilterOptions = toJS(rs.data).map(
						(m: any) =>
							({
								label: m,
								value: m,
							}) as ISelectOption
					);
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const countryFilters = this.get();

		if (countryFilters) orFiltersGroups.push(countryFilters);
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterCountryStore;
