import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCustomerStoreOM {
	@observable rootStore;
	@observable preFilter: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}
	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		return getFilterObjectArrayOfStringKeyBySize(
			100,
			this.preFilter,
			"order.foOriginalCustomerOM",
			"contains"
		);
	};

	@action set = (value: string) => (this.preFilter = value);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const customerFilters = this.get();
		andFilters = andFilters.concat(customerFilters);
		return andFilters;
	}
}

export default PreFilterCustomerStoreOM;
