import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LibraryBooks, Save } from "@mui/icons-material";
import {
	Alert,
	Box,
	Button,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
} from "@mui/material";
import { useStores } from "../stores/RootStore";
import { toast } from "react-toastify";
import DialogForm from "../../../../components/Dialog/DialogForm";
import UndoButton from "../../../../components/Dialog/Buttons/UndoButton";
import ApplyButton from "../../../../components/Dialog/Buttons/ApplyButton";
import QuotationBatchEditionFields from "./QuotationBatchEditionFields";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import { groupsQuotationManager } from "../../../../constants/AuthGroups/quotationPage";

const QuotationEditionModal: React.FC = () => {
	const { t } = useTranslation(["general", "quotation"]);

	const {
		resetData,
		updateBatchData,
		formHasChanges,
		loadSalesmanOptions,
		loadCustomerOptions,
	} = useStores().quotationBatchEditionStore;
	const { isAuthorizedGroup } = useAuthStore();

	const isQuotationManagerUser = isAuthorizedGroup(groupsQuotationManager);

	const { listTotalResult } = useStores().listStore;
	const validListSize = listTotalResult > 0 && listTotalResult <= 80;

	const [open, setOpen] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(true);

	const setModalOpen = () => {
		resetData();
		setShowAlert(true);
		setOpen(true);
	};

	const setModalClosed = () => setOpen(false);

	const handleUndo = () => {
		resetData();
		setModalClosed();
	};

	const handleSubmit = () => {
		updateBatchData()
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data));

		setModalClosed();
	};

	const loadOptions = React.useCallback(() => {
		if (isQuotationManagerUser) loadSalesmanOptions();
		loadCustomerOptions();
	}, [loadSalesmanOptions, loadCustomerOptions, isQuotationManagerUser]);

	React.useEffect(() => {
		loadOptions();
	}, [loadOptions]);

	return (
		<>
			<Box p={2}>
				<Typography>
					<Button
						variant="contained"
						onClick={setModalOpen}
						color="secondary"
						size="small"
						disabled={!validListSize}
						startIcon={<LibraryBooks />}
					>
						{t("batchEdition")}
					</Button>
				</Typography>
			</Box>
			<DialogForm
				id="dialog-batch-edition"
				open={open}
				title={t("batchEdition")}
				onClose={setModalClosed}
				maxWidth="md"
			>
				<DialogContent>
					<Grid container spacing={2} overflow="none" width={600}>
						{showAlert && (
							<Grid item xs={12}>
								<Alert severity="warning" onClose={() => setShowAlert(false)}>
									{t("batchAlert", { ns: "quotation" })}
								</Alert>
							</Grid>
						)}
						<QuotationBatchEditionFields />
					</Grid>
				</DialogContent>
				<DialogActions>
					<UndoButton
						id="dialog-batch-edition"
						onUndo={handleUndo}
						disabled={!formHasChanges}
						title={t("undoChanges", { ns: "general" })}
					/>
					<ApplyButton
						id="dialog-batch-edition"
						onSubmit={handleSubmit}
						title={t("saveChanges", { ns: "general" })}
						disabled={!formHasChanges}
						startIcon={<Save />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(QuotationEditionModal);
