import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "../../../stores/RootStore";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import { Grid, Stack } from "@mui/material";

const GokDatePreFilter: React.FC = () => {
	const { t } = useTranslation("order");
	const { setStart, setEnd, preFilter } = useStores().preFilterGokDateStore;
	const { modalOpen } = useStores().filterStore;
	const startOfMonthDate = moment().startOf("month");

	const [startDate, setStartDate] = useState<Date | null>(
		preFilter && preFilter.startDate ? preFilter.startDate : null
	);
	const [endDate, setEndDate] = useState<Date | null>(
		preFilter && preFilter.endDate ? preFilter.endDate : null
	);
	const [startDateIsValid, setStartDateIsValid] = useState<boolean>(
		preFilter ? true : false
	);

	const handleStartDateChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (moment(dataItem, "dd/MM/yyyy", true).isValid()) {
			setStart(newDateMoment.toDate());
			setStartDateIsValid(true);
		} else if (dataItem == null) {
			setStart(undefined);
			setEnd(undefined);
			setStartDateIsValid(false);
		}
		setStartDate(dataItem);
	};

	const handleEndDateChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (moment(dataItem, "dd/MM/yyyy", true).isValid()) {
			setEnd(newDateMoment.toDate());
		} else if (dataItem == null) {
			setEnd(undefined);
		}
		setEndDate(dataItem);
	};

	const clearDates = React.useCallback(() => {
		if (!preFilter || !preFilter.startDate) {
			setStartDate(null);
			setEndDate(null);
			setStartDateIsValid(false);
			return;
		}
	}, [preFilter]);

	React.useEffect(() => {
		clearDates();
	}, [modalOpen, clearDates]);

	return (
		<Grid item xs={12}>
			<Stack direction="row" spacing={2}>
				<Grid item xs={6}>
					<DateForm
						editable
						label={t("preFilters.gokDate")}
						inputFormat="dd/MM/yyyy"
						value={startDate}
						onChange={handleStartDateChange}
						size="small"
						minDate={startOfMonthDate.month(0).year(2020).toDate()}
						maxDate={startOfMonthDate.month(0).year(2030).toDate()}
					/>
				</Grid>
				<Grid item xs={6}>
					<DateForm
						editable
						label={t("preFilters.gokDateEnd")}
						inputFormat="dd/MM/yyyy"
						value={endDate}
						onChange={handleEndDateChange}
						size="small"
						disabled={!(startDateIsValid && startDate)}
						minDate={moment(startDate).add(1, "days").toDate()}
						maxDate={startOfMonthDate.month(0).year(2030).toDate()}
					/>
				</Grid>
			</Stack>
		</Grid>
	);
};

export default observer(GokDatePreFilter);
