import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { IPreFilterDateRange } from "../interfaces/IPreFilter";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterGokDateStore {
	@observable rootStore;
	@observable preFilter?: IPreFilterDateRange = undefined;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = undefined;
	};

	@action clean = () => (this.preFilter = undefined);

	@action get = () =>
		this.preFilter && this.preFilter.endDate
			? [
					getFilterObject("order.GreenOk", this.preFilter?.startDate, "gte"),
					getFilterObject("order.GreenOk", this.preFilter?.endDate, "lte"),
				]
			: this.preFilter && this.preFilter.startDate
				? [getFilterObject("order.GreenOk", this.preFilter?.startDate, "eq")]
				: [];

	@action setStart = (startDate: Date | undefined) => {
		this.preFilter = { ...this.preFilter, startDate: startDate };
	};

	@action setEnd = (endDate: Date | undefined) => {
		if (this.preFilter && this.preFilter.startDate)
			this.preFilter.endDate = endDate;
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const GokDateFilters = this.get();

		if (GokDateFilters) andFilters = andFilters.concat(GokDateFilters);

		return andFilters;
	}
}

export default PreFilterGokDateStore;
