import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterQuotationCustomerStore {
	@observable rootStore;
	@observable preFilter: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}
	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		return getFilterObjectArrayOfStringKeyBySize(
			200,
			this.preFilter,
			"quotationCustomer",
			"contains"
		);
	};

	@action set = (value: string) => {
		if (value !== "") this.rootStore.filterStore.hasPreFiltersApplied = true;

		this.preFilter = value;
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const customerFilters = this.get();
		if (customerFilters) orFiltersGroups.push(customerFilters);
	}
}

export default PreFilterQuotationCustomerStore;
