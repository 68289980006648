import * as React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { DataGridApi } from "../../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../../constants/gridConst";
import { ToastContainer } from "react-toastify";
import { useStores } from "../stores/RootStore";

type HomePageGrid = {
	columns: Array<IColumnProps>;
	calculateWidth?: boolean;
};

const MenuGrid: React.FC<HomePageGrid> = ({ columns, calculateWidth }) => {
	const { allRecords } = useStores().listStore;
	const { filters } = useStores().filterStore;

	return (
		<>
			<DataGridApi
				columns={columns}
				fetchData={allRecords}
				pageable={gridConst.pageable}
				pageSize={gridConst.pageSize}
				filterBy={filters}
				rowHeight={10}
				reorderable={true}
				resizable={true}
				className="k-grid-lg"
				calculateWidth={calculateWidth ?? true}
			/>
			<ToastContainer />
		</>
	);
};

export default observer(MenuGrid);
