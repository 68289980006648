import React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import HomePageGrid from "./HomePageGrid";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import TrucksPreFilters from "../PreFilters/TrucksPreFilters";
import HomePageCsvExport from "./HomePageCsvExport";
import OrderBatchEdition from "../../containers/BatchEdition/OrderBatchEdition";
import { IPreFilterMonthAvailability } from "../PreFilters/interfaces/IPreFilter";
import { OrderbookType } from "../../../../../utils/GenericTypes";
import { OrderbookEnum } from "../../../../../utils/enums/OrderbookEnum";
import BusPreFilters from "../PreFilters/BusPreFilters";
import EuropeFactoryPreFilters from "../PreFilters/EuropeFactoryPreFilters";

interface OrderBookPageProps {
	orderbookTitleSource: string;
	orderbookSubTitleSource: string;
	orderbookPageTitleSource: string;
	columns?: IColumnProps[];
	monthAvailability?: IPreFilterMonthAvailability;
	type: OrderbookType;
}

const getFilterByType = (
	type: OrderbookType,
	monthAvailability?: IPreFilterMonthAvailability
) => {
	switch (type) {
		case OrderbookEnum.Truck:
			return <TrucksPreFilters monthAvailability={monthAvailability} />;
		case OrderbookEnum.Bus:
			return <BusPreFilters />;
		case OrderbookEnum.Europe:
			return <EuropeFactoryPreFilters />;
		default:
			return <></>;
	}
};

const OrderBookPage: React.FC<OrderBookPageProps> = ({
	orderbookTitleSource,
	orderbookSubTitleSource,
	orderbookPageTitleSource,
	columns,
	monthAvailability,
	type,
}) => {
	const { t } = useTranslation("menu");

	const breadcrumbs = [
		{
			title: t("home"),
			href: "/",
			main: false,
		},
		{
			title: `${t(orderbookTitleSource)} - ${t(orderbookSubTitleSource)}`,
			main: true,
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox
						breadcrumbs={breadcrumbs}
						pageTitle={t(orderbookPageTitleSource, {
							ob: t(orderbookSubTitleSource),
						})}
					/>
					<HomePageCsvExport />
					{getFilterByType(type, monthAvailability)}
				</Box>
			</Grid>
			{type !== OrderbookEnum.Europe && <OrderBatchEdition />}
			<Grid item xs={12}>
				{columns && <HomePageGrid columns={columns} />}
			</Grid>
		</Grid>
	);
};

export default observer(OrderBookPage);
