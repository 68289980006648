import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { ISelectOption } from "../interfaces/IPreFilter";
import { getDealerTracbelOptions } from "../../../../../../utils/SelectOptions";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterRegionStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";
	@observable preFilterOptions: Array<ISelectOption> = [];
	@observable isTracbelUser: boolean = false;

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action setIsTracbelUser = (value: boolean) => (this.isTracbelUser = value);

	@action reset = () => {
		this.preFilter = "";
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: this.preFilter
					.split(",")
					.map((d: string) =>
						getFilterObject("order.foDealerRegion", d, "startswith")
					);

	@action set = (region: string) => (this.preFilter = region);

	@action getOptions = async (menu: string) => {
		this.loading = true;
		this.preFilterOptions = [];
		if (this.isTracbelUser) {
			this.preFilterOptions = getDealerTracbelOptions();
		} else {
			await this.api.regionService
				.getOrderBookFilterOptions(menu)
				.then((rs: any) => {
					if (rs.data)
						this.preFilterOptions = toJS(rs.data).map(
							(m: any) =>
								({
									label: m.description,
									value: m.regions.join(","),
								}) as ISelectOption
						);
				})
				.finally(() => (this.loading = false));
		}
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const regionFilters = this.get();

		if (regionFilters) orFiltersGroups.push(regionFilters);
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterRegionStore;
