import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { Grid } from "@mui/material";

const CommercialModelPreFilter: React.FC = () => {
	const { t } = useTranslation("order");
	const { menu } = useStores().listStore;
	const { set, preFilter, setMenu } = useStores().preFilterCommercialModelStore;
	const { applyFilters } = useStores().filterStore;

	const menuOnChange = React.useCallback(() => {
		setMenu(menu);
	}, [menu, setMenu]);

	React.useEffect(() => {
		menuOnChange();
	}, [menuOnChange]);

	return (
		<Grid item xs>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				id="commercialModelPreFilter"
				label={t(`preFilters.commercialModel`)}
				size="small"
				inputProps={{ maxLength: 50 }}
				editable
			/>
		</Grid>
	);
};

export default observer(CommercialModelPreFilter);
