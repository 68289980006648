import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "../../stores/RootStore";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { Grid } from "@mui/material";

const QuotationDatePreFilter: React.FC = () => {
	const { t } = useTranslation("quotation");
	const { setStart, setEnd, preFilter } =
		useStores().preFilterQuotationDateStore;
	const startOfMonthDate = moment().startOf("month");

	const [startDate, setStartDate] = useState<Date | null>(
		preFilter && preFilter.startDate ? preFilter.startDate : null
	);
	const [endDate, setEndDate] = useState<Date | null>(
		preFilter && preFilter.endDate ? preFilter.endDate : null
	);
	const [startDateIsValid, setStartDateIsValid] = useState<boolean>(
		preFilter ? true : false
	);

	const handleStartDateChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (moment(dataItem, "dd/MM/yyyy", true).isValid()) {
			setStart(newDateMoment.toDate());
			setStartDateIsValid(true);
		} else if (dataItem == null) {
			setStart(undefined);
			setEnd(undefined);
			setStartDateIsValid(false);
		}
		setStartDate(dataItem);
	};

	const handleEndDateChange = (dataItem: any) => {
		const newDateMoment = moment(dataItem);
		if (moment(dataItem, "dd/MM/yyyy", true).isValid()) {
			setEnd(newDateMoment.toDate());
		} else if (dataItem == null) {
			setEnd(undefined);
		}
		setEndDate(dataItem);
	};

	return (
		<>
			<Grid item xs={6}>
				<DateForm
					editable
					label={t("preFilters.quotationDate")}
					inputFormat="dd/MM/yyyy"
					value={startDate}
					onChange={handleStartDateChange}
					size="small"
					minDate={startOfMonthDate.month(0).year(2020).toDate()}
					maxDate={startOfMonthDate.month(0).year(2030).toDate()}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateForm
					editable
					label={t("preFilters.quotationDateEnd")}
					inputFormat="dd/MM/yyyy"
					value={endDate}
					onChange={handleEndDateChange}
					size="small"
					disabled={!(startDateIsValid && startDate)}
					minDate={moment(startDate).add(1, "days").toDate()}
					maxDate={startOfMonthDate.month(0).year(2030).toDate()}
				/>
			</Grid>
		</>
	);
};

export default observer(QuotationDatePreFilter);
