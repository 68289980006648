import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";
import _ from "lodash";
import {
	BatchDataProps,
	BatchEngineeringUserDataProps,
	BatchFinancialUserDataProps,
} from "../interfaces/BatchEdition/BatchDataProps";
import {
	batchDataModel,
	batchEngineeringUserDataModel,
} from "../models/BatchDataModel";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { OrderMCDeliveryBatchEditProps } from "../interfaces/BatchEdition/OrderMCDeliveryBatchEditProps";
import { OrderMCManagementDetailBatchEditProps } from "../interfaces/BatchEdition/OrderMCManagementDetailBatchEditProps";
import { StringFormProps } from "../interfaces/BatchEdition/StringFormProps";
import { getLabel } from "../../../../utils/PaymentTermsUtil";
import {
	FOLLOWUP_MENU_ARG_BUS,
	FOLLOWUP_MENU_ARG_TRUCK,
} from "../../../../constants/Menus/FollowUpMenuUtils";

configure({ enforceActions: "always" });

class BatchEditionStore {
	@observable batchData: BatchDataProps = batchDataModel;
	@observable batchFinancialUserData: BatchFinancialUserDataProps =
		batchDataModel;
	@observable batchEngineeringUserData: BatchEngineeringUserDataProps =
		batchEngineeringUserDataModel;
	@observable loading: boolean = false;
	@observable districtsList: Array<SelectOptionProps> = [];
	@observable regionsList: Array<SelectOptionProps> = [];
	@observable formHasChanges: boolean = false;
	@observable deliveryProbability: string = "";
	@observable dealerOptions: Array<SelectOptionProps> = [];
	@observable currencyOptions: Array<SelectOptionProps> = [];
	@observable vehicleLocationOptions: Array<SelectOptionProps> = [];
	@observable paymentTermsOptions: Array<SelectOptionProps> = [];
	@observable deliveryProbabilityOptions: Array<SelectOptionProps> = [];
	@observable paqueteOptions: Array<SelectOptionProps> = [];
	@observable itemsToBeUpdated: Array<string> = [];

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private readonly api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetData = () => {
		this.resetBatchData();
		this.setFormHasChanges(false);
	};

	@action private resetBatchData = () => {
		this.batchData = batchDataModel;
		this.batchEngineeringUserData = batchEngineeringUserDataModel;
	};

	@action setItemsToBeUpdated = (itemsToBeUpdated: Array<string>) =>
		(this.itemsToBeUpdated = itemsToBeUpdated);

	@action onChangeItemToBeUpdated = (
		item: string | Array<string>,
		checked: boolean
	) => {
		if (checked) {
			if (typeof item === "string")
				this.setItemsToBeUpdated([item, ...this.itemsToBeUpdated]);
			else this.setItemsToBeUpdated(item);
		} else {
			if (typeof item === "string") {
				const newItems = this.itemsToBeUpdated.filter((f) => f !== item);
				this.setItemsToBeUpdated(newItems);
			} else this.setItemsToBeUpdated([]);
		}
	};

	@action setFormHasChanges = (formHasChanges: boolean) =>
		(this.formHasChanges = formHasChanges);

	@action checkAndSetFormHasChanges = () => {
		const batchDataHasChanges = !_.isEqual(batchDataModel, this.batchData);
		this.setFormHasChanges(batchDataHasChanges);
	};

	@action setOptionsDeliveryProbability = (options: Array<SelectOptionProps>) =>
		(this.deliveryProbabilityOptions = options);

	@action setOptionsCurrency = (options: Array<SelectOptionProps>) =>
		(this.currencyOptions = options);

	@action setDeliveryProbability = (newValue: string) => {
		this.batchData.orderMCDelivery.deliveryProbability = newValue;
		this.checkAndSetFormHasChanges();
	};

	@action setOptionsDealer = async () => {
		if (this.dealerOptions.length > 0) return;

		this.loading = true;
		await this.api.dealerService
			.getDictionaryIdNameByCountry("ARG")
			.then((rs: any) => {
				this.setDealerOptions(toJS(rs.data));
			})
			.finally(() => (this.loading = false));
	};

	@action private setDealerOptions = (data: any) => {
		this.dealerOptions = [];

		if (!data) return;

		const arrayOfKeyValues = Object.entries(data);
		this.dealerOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action setOrderMCManagementDetailBatchEditByKey = <
		K extends keyof OrderMCManagementDetailBatchEditProps,
	>(
		newValue: OrderMCManagementDetailBatchEditProps[K],
		fieldKey: K
	) => {
		const _orderMCManagementDetailBatchEditProps: OrderMCManagementDetailBatchEditProps =
			Object.assign({}, this.batchData.orderMCManagementDetail);
		_orderMCManagementDetailBatchEditProps[fieldKey] = newValue;
		this.batchData.orderMCManagementDetail =
			_orderMCManagementDetailBatchEditProps;
		this.checkAndSetFormHasChanges();
	};

	@action setMCManagementDetailDestination = (newValue: StringFormProps) => {
		this.batchData.orderMCManagementDetail.destination = newValue;
		this.checkAndSetFormHasChanges();
	};

	@action setCustomerSAS = (newValue: StringFormProps) => {
		this.batchData.customerSAS = newValue;
		this.checkAndSetFormHasChanges();
	};

	@action setBatchDataByKey = <K extends keyof BatchDataProps>(
		newValue: BatchDataProps[K],
		fieldKey: K
	) => {
		const _batchDataProps: BatchDataProps = Object.assign({}, this.batchData);
		_batchDataProps[fieldKey] = newValue;
		this.batchData = _batchDataProps;
		this.checkAndSetFormHasChanges();
	};

	@action private setVehicleLocationOptions = (data: any) => {
		this.vehicleLocationOptions = [];

		if (!data) return;

		const arrayOfKeyValues = Object.entries(data);
		this.vehicleLocationOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action setOptionsVehicleLocation = async () => {
		await this.api.vehicleLocationService
			.getDictionaryIdNameByCountry("ARG")
			.then((response) => {
				this.setVehicleLocationOptions(response.data);
			});
	};

	@action private setPaqueteOptions = (data: any) => {
		this.paqueteOptions = [];

		if (!data) return;

		this.paqueteOptions = toJS(data).map(
			(m: any) =>
				({
					label: m.paqueteDescription,
					value: m.id,
				}) as SelectOptionProps
		);
	};

	@action setOptionsPaqueteForm = async () => {
		await this.api.paqueteService.getPaqueteList().then((rs: any) => {
			if (rs.data) this.setPaqueteOptions(rs.data);
		});
	};

	@action private setPaymentTermsOptions = (data: any, language: string) => {
		this.paymentTermsOptions = [];

		if (!data) return;

		this.paymentTermsOptions = toJS(data).map(
			(m: any) =>
				({
					label: getLabel(m, language),
					value: m.id,
				}) as SelectOptionProps
		);
	};

	@action setOptionsPaymentForm = async (language: string) => {
		await this.api.paymentTermsService
			.getPaymentTerms("ARG")
			.then((rs: any) => {
				if (rs.data) this.setPaymentTermsOptions(rs.data, language);
			});
	};

	@action setMCDeliveryDataByKey = <
		K extends keyof OrderMCDeliveryBatchEditProps,
	>(
		newValue: OrderMCDeliveryBatchEditProps[K],
		fieldKey: K
	) => {
		const _orderMCDeliveryBatchEdit: OrderMCDeliveryBatchEditProps =
			Object.assign({}, this.batchData.orderMCDelivery);
		_orderMCDeliveryBatchEdit[fieldKey] = newValue;
		this.batchData.orderMCDelivery = _orderMCDeliveryBatchEdit;
		this.checkAndSetFormHasChanges();
	};

	@action private setBatchData = (batchData: BatchDataProps) =>
		(this.batchData = batchData);

	@action private setFinancialUserBatchData = (
		batchFinancialUserData: BatchFinancialUserDataProps
	) => (this.batchFinancialUserData = batchFinancialUserData);

	@action private setEngineeringUserBatchData = (
		batchEngineeringUserData: BatchEngineeringUserDataProps
	) => (this.batchEngineeringUserData = batchEngineeringUserData);

	@action updateBatchData = async (userUpdatePermission: string) => {
		if (this.itemsToBeUpdated.length <= 0) return;

		this.rootStore.listStore.setLoading(true);

		switch (userUpdatePermission) {
			case "UpdateFollowUpArg":
				this.updateMarketComapanyUser();
				break;
			case "UpdateFollowUpArgFinancial":
				this.updateMarketFinancialUser();
				break;
			case "UpdateFollowUpArgEngineering":
				this.updateMarketEngineeringUser();
				break;
			default:
				return;
		}
	};

	private async updateMarketComapanyUser() {
		this.setBatchData({
			...this.batchData,
			itemsToBeUpdated: this.itemsToBeUpdated,
		});

		const batchData = toJS(this.batchData);

		if (this.rootStore.listStore.menu === FOLLOWUP_MENU_ARG_TRUCK) {
			return await this.api.followUpArgService
				.updateBatch(batchData)
				.then(() => this.resetData())
				.finally(() => {
					this.rootStore.listStore.setRefresh();
					this.setItemsToBeUpdated([]);
				});
		}
		if (this.rootStore.listStore.menu === FOLLOWUP_MENU_ARG_BUS) {
			return await this.api.followUpArgService
				.updateBatchBus(batchData)
				.then(() => this.resetData())
				.finally(() => {
					this.rootStore.listStore.setRefresh();
					this.setItemsToBeUpdated([]);
				});
		}
	}

	private async updateMarketFinancialUser() {
		this.setFinancialUserBatchData({
			...this.batchData,
			itemsToBeUpdated: this.itemsToBeUpdated,
		});

		const batchData = toJS(this.batchFinancialUserData);

		if (this.rootStore.listStore.menu === FOLLOWUP_MENU_ARG_TRUCK) {
			return await this.api.followUpArgService
				.updateBatchFinancialUser(batchData)
				.then(() => this.resetData())
				.finally(() => {
					this.rootStore.listStore.setRefresh();
					this.setItemsToBeUpdated([]);
				});
		}
		if (this.rootStore.listStore.menu === FOLLOWUP_MENU_ARG_BUS) {
			return await this.api.followUpArgService
				.updateBatchFinancialUserBus(batchData)
				.then(() => this.resetData())
				.finally(() => {
					this.rootStore.listStore.setRefresh();
					this.setItemsToBeUpdated([]);
				});
		}
	}

	private async updateMarketEngineeringUser() {
		this.setEngineeringUserBatchData({
			paqueteId: this.batchData?.orderMCManagementDetail?.paqueteId,
			itemsToBeUpdated: this.itemsToBeUpdated,
		});

		return await this.api.followUpArgService
			.updateBatchEngineeringUser(this.batchEngineeringUserData)
			.then(() => this.resetData())
			.finally(() => {
				this.rootStore.listStore.setRefresh();
				this.setItemsToBeUpdated([]);
			});
	}
}

export default BatchEditionStore;
