import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores/RootStore";
import { getDefaultArrayStringOption } from "../../../../../../utils/SelectOptions";
import { Grid } from "@mui/material";
import MultiSelectForm from "../../../../../../components/FormInputs/MultiSelectForm";

const VehicleLocationPreFilter: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);
	const { preFilter, preFilterOptions, set } =
		useStores().preFilterVehicleLocationStore;
	const defaultItem = getDefaultArrayStringOption(t, preFilterOptions);

	return (
		<Grid item xs={12}>
			<MultiSelectForm
				label={t("preFilters.vehicleLocation")}
				labelId={"pre-filter-vehicleLocation-label"}
				value={preFilter}
				options={preFilterOptions}
				size="small"
				editable
				defaultItem={defaultItem}
				onChange={(values) => set(values)}
			/>
		</Grid>
	);
};

export default observer(VehicleLocationPreFilter);
