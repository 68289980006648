import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import {
	getDefaultStringOption,
	getQuotationStatusSASOptions,
} from "../../../../../utils/SelectOptions";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { Grid } from "@mui/material";

const StatusSASPreFilter: React.FC = () => {
	const { t } = useTranslation("quotation");
	const { preFilter, set } = useStores().preFilterStatusSASStore;
	const defaultItem = getDefaultStringOption(t);
	const preFilterOptions = getQuotationStatusSASOptions(t);

	return (
		<Grid item xs={6}>
			<SelectForm
				label={t("preFilters.statusSAS")}
				labelId={"pre-filter-statusSAS-label"}
				value={preFilter}
				options={preFilterOptions}
				size="small"
				editable
				defaultItem={defaultItem}
				onChange={(e) => set(e.target.value)}
			/>
		</Grid>
	);
};

export default observer(StatusSASPreFilter);
