import React from "react";
import { configure } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import FilterStore from "./FilterStore";
import ListStore from "./ListStore";
import PreFilterLineStore from "../components/PreFilters/stores/PreFilterLineStore";
import PreFilterChassiAOStore from "../components/PreFilters/stores/PreFilterChassiAOStore";
import PreFilterDeliveryProposalStore from "../components/PreFilters/stores/PreFilterDeliveryProposalStore";
import PreFilterMonthAvailabilityStore from "../components/PreFilters/stores/PreFilterMonthAvailabilityStore";
import PreFilterAsWeekStore from "../components/PreFilters/stores/PreFilterAsWeekStore";
import PreFilterCommercialModelStore from "../components/PreFilters/stores/PreFilterCommercialModelStore";
import PreFilterLineSequenceDateStore from "../components/PreFilters/stores/PreFilterLineSequenceDateStore";
import PreFilterZoneStore from "../components/PreFilters/stores/PreFilterZoneStore";
import PreFilterSalesmanStore from "../components/PreFilters/stores/PreFilterSalesmanStore";
import PreFilterQuotationCustomerStore from "../components/PreFilters/stores/PreFilterQuotationCustomerStore";
import PreFilterQuotationSalesmanStore from "../components/PreFilters/stores/PreFilterQuotationSalesmanStore";
import BatchEditionStore from "./BatchEditionStore";
import PreFilterCustomerOMStore from "../components/PreFilters/stores/PreFilterCustomerOMStore";
import PreFilterCustomerSASStore from "../components/PreFilters/stores/PreFilterCustomerSASStore";

configure({ enforceActions: "always" });

class RootStore {
	filterStore: any = {};
	listStore: any = {};
	batchEditionStore: any = {};
	preFilterLineStore: any = {};
	preFilterLineSequenceDateStore: any = {};
	preFilterChassiAOStore: any = {};
	preFilterDeliveryProposalStore: any = {};
	preFilterMonthAvailabilityStore: any = {};
	preFilterRegionStore: any = {};
	preFilterAsWeekStore: any = {};
	preFilterCommercialModelStore: any = {};
	preFilterZoneStore: any = {};
	preFilterSalesmanStore: any = {};
	preFilterQuotationCustomerStore: any = {};
	preFilterQuotationSalesmanStore: any = {};
	preFilterCustomerSASStore: any = {};
	preFilterCustomerOMStore: any = {};

	constructor() {
		const api = new CtadmvenApi();

		this.filterStore = new FilterStore(this);
		this.listStore = new ListStore(this, api);
		this.batchEditionStore = new BatchEditionStore(this, api);
		this.preFilterChassiAOStore = new PreFilterChassiAOStore(this);
		this.preFilterLineSequenceDateStore = new PreFilterLineSequenceDateStore(
			this
		);
		this.preFilterDeliveryProposalStore = new PreFilterDeliveryProposalStore(
			this
		);
		this.preFilterMonthAvailabilityStore = new PreFilterMonthAvailabilityStore(
			this
		);
		this.preFilterLineStore = new PreFilterLineStore(this, api);
		this.preFilterZoneStore = new PreFilterZoneStore(this, api);
		this.preFilterAsWeekStore = new PreFilterAsWeekStore(this);
		this.preFilterCommercialModelStore = new PreFilterCommercialModelStore(
			this
		);
		this.preFilterSalesmanStore = new PreFilterSalesmanStore(this);
		this.preFilterQuotationCustomerStore = new PreFilterQuotationCustomerStore(
			this
		);
		this.preFilterQuotationSalesmanStore = new PreFilterQuotationSalesmanStore(
			this
		);
		this.preFilterCustomerSASStore = new PreFilterCustomerSASStore(this);
		this.preFilterCustomerOMStore = new PreFilterCustomerOMStore(this);
	}
}

const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
export default RootStore;
