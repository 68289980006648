import { State } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { QuotationProps } from "../interfaces/QuotationProps";
import RootStore from "./RootStore";
import { CultureType } from "../../../../utils/GenericTypes";
import moment from "moment";
import { downloadCsv } from "../../../../utils/downloadCsv";
import { CsvOptionsProps } from "../interfaces/CsvOptionsProps";
import { CSV_DISABLED_QUOTATIONS } from "../../../../constants/csvButtonStorage";
import { getCsvButtonTimer } from "../../../../utils/CheckCsvTimer";

configure({ enforceActions: "always" });

class ListStore {
	@observable rootStore;
	@observable quotation: Array<QuotationProps> = [];
	@observable loading: boolean = false;
	@observable lastDataState: State | undefined;
	@observable isQuotationManagerUser: boolean = false;
	@observable listTotalResult: number = 0;
	@observable refresh: number = 0;
	@observable includeVariants: boolean = false;

	menu: string = "Quotation";

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.loading = true;
		this.rootStore.filterStore.cleanAllFilters();
	};

	@action setIsButtonCsvDisabled = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_QUOTATIONS, value.toString());

	@action setLoading = (value: boolean) => (this.loading = value);
	@action setRefresh = (value: number) => this.refresh++;

	@action setIncludeVariants = (value: boolean) =>
		(this.includeVariants = value);

	@action setIsQuotationManagerUser = (value: boolean) =>
		(this.isQuotationManagerUser = value);

	@action private setListTotalResult = (value: number) =>
		(this.listTotalResult = value);

	@action allRecords = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);
		if (this.isQuotationManagerUser) {
			return await this.api.quotationService
				.getQuotations(this.lastDataState)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return Object.assign({ selected: false }, json.data);
				})
				.finally(() => this.setLoading(false));
		} else {
			return await this.api.quotationService
				.getListForSalesmanQuotations(this.lastDataState)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return Object.assign({ selected: false }, json.data);
				})
				.finally(() => this.setLoading(false));
		}
	};

	@action allRecordsForSalesman = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);

		return await this.api.quotationService
			.getListForSalesmanQuotations(this.lastDataState)
			.then((json) => Object.assign({ selected: false }, json.data))
			.finally(() => this.setLoading(false));
	};

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action private setLastDataState = (value: State) =>
		(this.lastDataState = value);

	@action csvExport = async (culture: CultureType) => {
		if (!this.lastDataState) return;

		this.setLoading(true);

		const fileName = `${this.menu}_${moment().format("YYYYMMDD_HHmmss")}.csv`;

		const csvOptions: CsvOptionsProps = {
			includeVariants: this.includeVariants,
			culture: culture,
		};

		this.setIsButtonCsvDisabled(getCsvButtonTimer());

		if (this.isQuotationManagerUser)
			return await this.api.quotationService
				.csvExport(this.lastDataState, csvOptions)
				.then((content: any) => downloadCsv(content, fileName))
				.finally(() => this.setLoading(false));
		else
			return await this.api.quotationService
				.csvExportForSalesman(this.lastDataState, csvOptions)
				.then((content: any) => downloadCsv(content, fileName))
				.finally(() => this.setLoading(false));
	};
}

export default ListStore;
