import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { BusMenus } from "../../../../../../constants/Menus/OrderMenuUtils";
import { Grid } from "@mui/material";

type ChassiAOProps = {
	type: "chassi" | "chassiAO" | "chassiFO" | "chassiFOOM";
};
const ChassiAO: React.FC<ChassiAOProps> = ({ type }) => {
	const { t } = useTranslation("order");
	const { set, preFilter, setMenu, hasFormatError } =
		useStores().preFilterChassiAOStore;
	const { menu } = useStores().listStore;
	const { applyFilters } = useStores().filterStore;

	const menuOnChange = React.useCallback(() => {
		setMenu(menu);
	}, [menu, setMenu]);

	React.useEffect(() => {
		menuOnChange();
	}, [menuOnChange]);

	return (
		<Grid item xs={12}>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				id={type}
				label={t(`preFilters.${type}`)}
				tooltip={
					BusMenus.some((x) => x === menu)
						? t("tooltips.chassiBusSearch")
						: t("tooltips.multipleSearch")
				}
				size="small"
				editable
				error={hasFormatError}
			/>
		</Grid>
	);
};

export default observer(ChassiAO);
