import React from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import {
	Badge,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import UndoButton from "../../../../components/Dialog/Buttons/UndoButton";
import ApplyButton from "../../../../components/Dialog/Buttons/ApplyButton";
import DialogForm from "../../../../components/Dialog/DialogForm";
import { useStores } from "../stores/RootStore";

interface PreFiltersProps {
	children: React.ReactNode;
}

const PreFilters: React.FC<PreFiltersProps> = ({ children }) => {
	const { t } = useTranslation("order");
	const { applyFilters, hasPreFiltersApplied, cleanAllFilters } =
		useStores().filterStore;

	const [open, setOpen] = React.useState(false);

	const setModalOpen = () => setOpen(true);
	const setModalClosed = () => setOpen(false);

	const handleApplyClick = () => {
		applyFilters();
		setModalClosed();
	};
	const handleUndoClick = () => {
		cleanAllFilters();
		setModalClosed();
	};

	return (
		<Box p={2}>
			<DialogForm
				id="dialog-pre-filter"
				open={open}
				title={t("preFilters.quickFilter")}
				onClose={setModalClosed}
				onEnterSubmit={handleApplyClick}
			>
				<DialogContent>
					<Grid container spacing={2} overflow="none">
						{children}
					</Grid>
				</DialogContent>
				<DialogActions>
					<UndoButton
						id="dialog-pre-filter"
						onUndo={handleUndoClick}
						title={t("preFilters.clear")}
					/>
					<ApplyButton
						id="dialog-pre-filter"
						onSubmit={handleApplyClick}
						title={t("preFilters.apply")}
						startIcon={<FilterAlt />}
					/>
				</DialogActions>
			</DialogForm>
			<Typography>
				<Badge color="success" variant="dot" invisible={!hasPreFiltersApplied}>
					<Button variant="contained" onClick={setModalOpen} size="small">
						{t("preFilters.quickFilter")}
					</Button>
				</Badge>
			</Typography>
		</Box>
	);
};

export default observer(PreFilters);
