import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import {
	OrderBookMenuType,
	TOTAL,
} from "../../../../../../constants/Menus/OrderMenuUtils";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCommercialModelStore {
	@observable menu: OrderBookMenuType = TOTAL;
	@observable rootStore;
	@observable preFilter: string = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}
	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");
	@action setMenu = (value: OrderBookMenuType) => (this.menu = value);

	@action getFilterBySize = (value: string) => {
		return getFilterObjectArrayOfStringKeyBySize(
			50,
			value,
			"commercialModel",
			"contains"
		);
	};

	@action set = (value: string) => {
		if (value !== "") this.rootStore.filterStore.hasPreFiltersApplied = true;

		this.preFilter = value;
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		andFilters = andFilters.concat(this.getFilterBySize(this.preFilter));

		return andFilters;
	}
}

export default PreFilterCommercialModelStore;
