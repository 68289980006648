import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import { Grid, GridProps } from "@mui/material";
import { getDefaultArrayStringOption } from "../../../../../../utils/SelectOptions";
import MultiSelectForm from "../../../../../../components/FormInputs/MultiSelectForm";

const PaquetePreFilter: React.FC<GridProps> = () => {
	const { t } = useTranslation(["order", "general"]);
	const { preFilter, preFilterOptions, set } =
		useStores().preFilterPaqueteStore;
	const defaultItem = getDefaultArrayStringOption(t, preFilterOptions);

	return (
		<Grid item xs={12}>
			<MultiSelectForm
				label={t("preFilters.paquete")}
				labelId={"pre-filter-paquete-label"}
				value={preFilter}
				options={preFilterOptions}
				size="small"
				color="secondary"
				editable
				defaultItem={defaultItem}
				onChange={(values) => set(values)}
			/>
		</Grid>
	);
};

export default observer(PaquetePreFilter);
