import * as React from "react";
import { ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { AssignmentInd } from "@mui/icons-material";

const SHAREPOINT_ROLES_DOCUMENTATION_URL =
	"https://volvogroup.sharepoint.com/:x:/r/sites/unit-commercial-solutions-latin-america/CSLA-SalesAdmin/Shared%20Documents/Applications/SAS%20LA/Role%20x%20Feature%20Mapping.xlsx?d=wc97908c60f024363a33b8858b67e63df&csf=1&web=1&e=hD0JPN";

const RolesDocumentationAction: React.FC = () => {
	return (
		<ListItemButton
			onClick={() => window.open(SHAREPOINT_ROLES_DOCUMENTATION_URL)}
		>
			<ListItemIcon>
				<AssignmentInd fontSize="small" />
			</ListItemIcon>
			<ListItemText primary="Role x Feature Guide" />
		</ListItemButton>
	);
};

export default RolesDocumentationAction;
