import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { getFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterReservationStatusStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: string = "";

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: [getFilterObject("reservationStatus", this.preFilter, "eq")];

	@action set = (value: string) => (this.preFilter = value);

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const statusFilters = this.get();

		if (statusFilters) orFiltersGroups.push(statusFilters);
	}

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterReservationStatusStore;
