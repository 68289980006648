import React from "react";
import { observer } from "mobx-react";
import ChassiAO from "./containers/ChassiAO";
import PreFilters from "./PreFilters";
import Country from "./containers/Country";
import { groupsAllowedToSeeCountryPreFilterOption } from "../../../../../constants/AuthGroups/orderDetails";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { useStores } from "../../stores/RootStore";
import CommercialModel from "./containers/CommercialModel";

const EuropeFactoryPreFilters: React.FC = () => {
	const { getOptions: getOptionsCountry } = useStores().preFilterCountryStore;
	const { isAuthorizedGroup } = useAuthStore();
	const { menu } = useStores().listStore;

	const shouldShowCountryPreFilter = isAuthorizedGroup(
		groupsAllowedToSeeCountryPreFilterOption
	);

	React.useEffect(() => {
		if (shouldShowCountryPreFilter) {
			getOptionsCountry(menu);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menu]);

	return (
		<PreFilters>
			<ChassiAO type="chassiFOOM" />
			{shouldShowCountryPreFilter && <Country />}
			<CommercialModel />
		</PreFilters>
	);
};

export default observer(EuropeFactoryPreFilters);
