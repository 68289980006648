import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { toast } from "react-toastify";
import OrderMCDeliveryContent from "./OrderMCDeliveryContent";
import OrderMCManagementDetailContent from "./OrderMCManagementDetailContent";
import { getCurrencyEnumOptions } from "../../../../../utils/SelectOptions";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { getUserUpdatePermission } from "../../../Details/utils/EditableFieldsUtils";
import FinancialUserDeliveryContent from "./FinancialUserDeliveryContent";
import EngineeringUserContent from "./EngineeringUserContent";
import { getFollowUpArgProbabilityOptions } from "../../../../../utils/enums/ArgDeliveryProbabilityEnum";
import BatchEdition from "../../../../../components/BatchEdition/BatchEdition";

const getBatchEditionInputFields = (userUpdatePermission: string) => {
	switch (userUpdatePermission) {
		case "UpdateFollowUpArg":
			return (
				<>
					<OrderMCDeliveryContent />
					<OrderMCManagementDetailContent />
				</>
			);

		case "UpdateFollowUpArgFinancial":
			return <FinancialUserDeliveryContent />;

		case "UpdateFollowUpArgEngineering":
			return <EngineeringUserContent />;

		default:
			return <></>;
	}
};

const FollowUpArgBatchEditionModal: React.FC = () => {
	const { t } = useTranslation(["order", "general"]);
	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const { isAuthorizedGroup } = useAuthStore();
	const userUpdatePermission = getUserUpdatePermission(isAuthorizedGroup);

	const {
		resetData,
		updateBatchData,
		formHasChanges,
		setOptionsDeliveryProbability,
		setOptionsCurrency,
		setOptionsDealer,
		setOptionsVehicleLocation,
		setOptionsPaqueteForm,
		setOptionsPaymentForm,
		itemsToBeUpdated,
	} = useStores().batchEditionStore;

	const validListSize = itemsToBeUpdated.length > 0;

	const initialFetch = React.useCallback(() => {
		setOptionsDeliveryProbability(getFollowUpArgProbabilityOptions(t));
		setOptionsCurrency(getCurrencyEnumOptions(t));
		setOptionsPaymentForm(language);
		setOptionsVehicleLocation();
		setOptionsPaqueteForm();
		setOptionsDealer();
		resetData();
	}, [
		setOptionsDeliveryProbability,
		t,
		setOptionsCurrency,
		setOptionsPaymentForm,
		language,
		setOptionsVehicleLocation,
		setOptionsPaqueteForm,
		setOptionsDealer,
		resetData,
	]);

	React.useEffect(() => {
		initialFetch();
	}, [initialFetch]);

	const handleSubmit = () => {
		updateBatchData(userUpdatePermission)
			.then(() =>
				toast.success(t("editRecordsWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data));
	};

	const batchEditionInputFields =
		getBatchEditionInputFields(userUpdatePermission);

	return (
		<BatchEdition
			id="follow-up-arg-batch-edition"
			title={t("batchEdition", { ns: "general" })}
			onUndo={resetData}
			onSubmit={handleSubmit}
			hasChanges={formHasChanges}
			hasItemsSelected={validListSize}
		>
			{batchEditionInputFields}
		</BatchEdition>
	);
};

export default observer(FollowUpArgBatchEditionModal);
