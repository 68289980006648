import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import PreFilters from "./PreFilters";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	getCurrencyEnumOptions,
	getPreFilterNullOptions,
} from "../../../../../utils/SelectOptions";
import { useTranslation } from "react-i18next";
import DeliveryProbabilityPreFilter from "./containers/DeliveryProbabilityPreFilter";
import PrevisionBillingMonthPreFilter from "./containers/PrevisionBillingMonthPreFilter";
import FirmBillingPreFilter from "./containers/FirmBillingPreFilter";
import FinancialDeliveryPreFilter from "./containers/FinancialDeliveryPreFilter";
import PhysicalDeliveryPreFilter from "./containers/PhysicalDeliveryPreFilter";
import FinalDeliveryPreFilter from "./containers/FinalDeliveryPreFilter";
import DealerPreFilter from "./containers/DealerPreFilter";
import DestinationPreFilter from "./containers/DestinationPreFilter";
import CurrencyPreFilter from "./containers/CurrencyPreFilter";
import PaymentFormPreFilter from "./containers/PaymentFormPreFilter";
import ShippingDatePreFilter from "./containers/ShippingDatePreFilter";
import VehicleLocationPreFilter from "./containers/VehicleLocationPreFilter";
import { ARG_DEALERS } from "../../../../../constants/AuthGroups/authGroups";
import CertificateDatePreFilter from "./containers/CertificateDatePreFilter";
import ChassiAOPreFilter from "./containers/ChassiAOPreFilter";
import CustomerSASPreFilter from "./containers/CustomerSASPreFilter";
import AsWeekDatePreFilter from "./containers/AsWeekDatePreFilter";
import GokDatePreFilter from "./containers/GokDatePreFilter";
import BusinessControlVehiclePreFilter from "./containers/BusinessControlVehiclePreFilter";
import PaquetePreFilter from "./containers/PaquetePreFilter";
import CustomerOMPreFilter from "./containers/CustomerOMPreFilter";
import { getFollowUpArgProbabilityOptions } from "../../../../../utils/enums/ArgDeliveryProbabilityEnum";

const TrucksPreFilters: React.FC = () => {
	const { t, i18n } = useTranslation(["order", "general"]);
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const { setOptions: setOptionsDeliveryProbability } =
		useStores().preFilterDeliveryProbabilityStore;
	const { setPreFiltersCheckedOptions, cleanAllFilters } =
		useStores().filterStore;
	const { setOptions: setOptionsCurrency } = useStores().preFilterCurrencyStore;
	const { getOptions: getOptionsDealer } = useStores().preFilterDealerStore;
	const { getOptions: getOptionsPaquete } = useStores().preFilterPaqueteStore;
	const { getOptions: getOptionsPaymentForm } =
		useStores().preFilterPaymentFormStore;
	const { getOptions: getOptionsVehicleLocation } =
		useStores().preFilterVehicleLocationStore;

	const { isAuthorizedGroup } = useAuthStore();
	const shouldShowDealerPreFilter = !isAuthorizedGroup(ARG_DEALERS);

	const fetchOptions = React.useCallback(() => {
		cleanAllFilters();
		setOptionsDeliveryProbability(getFollowUpArgProbabilityOptions(t));
		setPreFiltersCheckedOptions(getPreFilterNullOptions(t));
		setOptionsCurrency(getCurrencyEnumOptions(t));
		getOptionsPaymentForm(language);
		getOptionsVehicleLocation();
		getOptionsPaquete();
		if (shouldShowDealerPreFilter) {
			getOptionsDealer();
		}
	}, [
		cleanAllFilters,
		setOptionsDeliveryProbability,
		t,
		setPreFiltersCheckedOptions,
		setOptionsCurrency,
		getOptionsPaymentForm,
		language,
		getOptionsVehicleLocation,
		shouldShowDealerPreFilter,
		getOptionsDealer,
		getOptionsPaquete,
	]);

	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	return (
		<PreFilters>
			<ChassiAOPreFilter vehicleType="Truck" type="chassiAO" />
			<DeliveryProbabilityPreFilter />
			<PrevisionBillingMonthPreFilter />
			<FirmBillingPreFilter />
			<FinancialDeliveryPreFilter />
			<PhysicalDeliveryPreFilter />
			<FinalDeliveryPreFilter />
			{shouldShowDealerPreFilter && <DealerPreFilter />}
			<PaquetePreFilter />
			<CustomerSASPreFilter xs={12} />
			<CustomerOMPreFilter xs={12} />
			<DestinationPreFilter />
			<CurrencyPreFilter />
			<PaymentFormPreFilter />
			<GokDatePreFilter />
			<AsWeekDatePreFilter />
			<ShippingDatePreFilter />
			<CertificateDatePreFilter />
			<VehicleLocationPreFilter />
			<BusinessControlVehiclePreFilter />
		</PreFilters>
	);
};

export default observer(TrucksPreFilters);
