import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/RootStore";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { Grid } from "@mui/material";

const QuotationSalesmanFilter: React.FC = () => {
	const { t } = useTranslation("order");
	const { set, preFilter } = useStores().preFilterQuotationSalesmanStore;
	const { applyFilters } = useStores().filterStore;

	return (
		<Grid item xs={12}>
			<TextForm
				value={preFilter}
				onChange={(e) => set(e.target.value)}
				onEnter={applyFilters}
				id="salesmanPreFilter"
				label={t(`preFilters.quotationSalesman`)}
				size="small"
				inputProps={{ maxLength: 200 }}
				editable
			/>
		</Grid>
	);
};

export default observer(QuotationSalesmanFilter);
